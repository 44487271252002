<template>
  <!-- 联系我们 -->
  <div class="box">
    <!-- 头部 -->
    <div class="logoTop">
      <span
        ><img src="../../assets/anluoyun.png" alt="" style="width: 13%"
      /></span>
      <span @click="viewList()"><van-icon name="bars" /></span>
    </div>
    <van-popup
      v-model="tabShow"
      class="tabShow"
      position="right"
      :style="{ height: '100%' }"
    >
      <div style="margin: 15px">
        <img
          src="../../assets/anluoyun.png"
          alt=""
          style="width: 80%; margin: 0 10%"
        />
      </div>
      <ul>
        <li><router-link to="/anLuoYun">网站首页</router-link></li>
        <li><router-link to="/anLuoYun/about">关于我们</router-link></li>
        <li><router-link to="/anLuoYun/serve">企业服务</router-link></li>
        <li><router-link to="/anLuoYun/recruit">公司招聘</router-link></li>
        <li><router-link to="/anLuoYun/message">企业资讯</router-link></li>
        <li class="select">
          <router-link to="/anLuoYun/contact">联系我们</router-link>
        </li>
      </ul>
    </van-popup>
    <!-- 标题 -->
    <div class="title">联系我们</div>
    <!-- 内容 -->
    <article class="center">
      <div class="boxList">
        <div>
          <div><span>河南安洛云科技有限公司</span></div>
          <div><span>业务接洽(工作日9:00 ── 18:00)</span></div>
          <div><span>联系电话:</span> 184 1841 8245</div>
          <div><span>官网地址:</span> www.baidu.com</div>
          <div><span>企业邮箱:</span> www.baidu.com</div>
        </div>
        <div style="margin-top:30px">
          <div><span>得贤人力资源</span></div>
          <div><span>电话:</span> 184 1841 8245</div>
          <div><span>邮箱:</span> 184 1841 8245</div>
        </div>
        <div style="margin-top:30px">
          <div><span>企业地址:</span>河南省洛阳市洛龙区863创智广场一号楼2201号</div>
          <div><span>固定电话:</span>184 1841 8245</div>
        </div>
      </div>
    </article>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tabShow: false,
    };
  },
  methods: {
    // 顶部tab菜单显示
    viewList() {
      if (this.tabShow == true) {
        this.tabShow = false;
      } else {
        this.tabShow = true;
      }
    },
    pushTo(row) {},
  },
};
</script>
<style scoped>
.box {
  height: 100%;
}
.logoTop {
  position: fixed;
  top: 0;
  width: 100%;
  height: 65px;
  background: #fff;
  box-shadow: 0px 1px 5px #ccc;
}
.logoTop span:nth-child(1) {
  position: absolute;
  left: 15px;
  top: 10px;
}
.logoTop span:nth-child(2) {
  position: absolute;
  right: 15px;
  top: 13px;
  font-size: 37px;
}
.tabShow {
  width: 130px;
}
.tabShow li {
  padding: 8px 0px;
  text-align: center;
  color: #000;
}
.tabShow a {
  color: #000;
}
.tabShow .select {
  background: #ccc;
}
.tabShow .select a {
  color: #0076f6;
}
.title {
  margin: 90px 15px 0 15px;
  font-size: 18px;
  color: #333;
  padding-bottom: 10px;
  border-bottom: 2px solid #2196f3;
}
.center {
  margin-top: 15px;
  padding: 8px 15px;
  font-size: 15px;
  line-height: 30px;
}
.boxList {
  background: #efefef;
  padding: 10px 15px;
  margin-bottom: 30px;
  color: #6f523e;
  font-size: 14px;
}
.boxList span {
  color: #6f523e;
  font-size: 14px;
  font-weight: 700;
}
</style>